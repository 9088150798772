<script>
import { ArrowUpIcon } from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import { API_URL, MEDIA_URL } from "@/core/api";
import Skeleton from "@/view/front/components/skeleton";
import GeneralService from "@/MainServices/GeneralService.js";

/**
 * page-jobs-by-sectors component
 */
export default {
  data() {
    return {
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,

      data: [],
      originalData: [],
    };
  },
  components: {
    Navbar,
    Skeleton,
    Switcher,
    Footer,
    ArrowUpIcon,
  },
  mounted() {
    this.getResults();
  },
  methods: {
    getResults(page = 1) {
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.data = response.data;
      });
    },
  },
};
</script>

<template>
  <div>
    <Navbar />
    <Skeleton v-if="loading" class=" pt-20" />
          <button type="button" @click="loadmore" v-if="show_load_more"> </button>
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <section class="section pt-23">
      <!-- new style -->
        <div class="container">
            <h1 class="heading_h_ch align-center m_title" style="background: url(images/hr-bg.png) no-repeat center 40px;">Search by Sectors</h1>
        </div>
      <div class="container">
        <div class="row">
       <div class="col-sm-12">
            <div class="row">
                <div class="col-xs-12 col-sm-6 col-md-4 col-lg-3"   v-for="(item, index) in data"
            :key="index">
                    <div class="well">
                        <router-link :to="
                          '/jobs-careers-pakistan/jobs-sector/' +
                          item.job_function_name
                            .toLowerCase()
                            .replace(/ /g, '-')
                            .replace(/[^\w-]+/g, '')">
                            {{ item.job_function_name }} 
                            <i class="pull-right fa fa-angle-right"></i>
                        </router-link> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    </section>

    <!--end section-->
    <!-- Job List End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
